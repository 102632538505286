import React from 'react';
import { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Prism from 'prismjs';
import SgLayout from '~layouts/sg-layout';
import CodeBlock from '~2-components/SgCodeBlock/SgCodeBlock';
import AwardsAndAccolades, { ColumnOne, ColumnTwo } from '~2-components/AwardsAndAccolades/AwardsAndAccolades';
import { AwardsAndAccoladesData } from '~data/awardsAndAccoladesData';
import AwardItem from '~2-components/AwardItem/AwardItem';
import AwardsAndAccoladesKontent from '~3-data-components/AwardsAndAccoladesKontent';

const awardsAndAccoladesEg = `import AwardsAndAccolades from '~2-components/AwardsAndAccolades/AwardsAndAccolades';

<AwardsAndAccolades
    title='' //Required
    cmsImage = React.node //Required if coming from CMS. Usually a Gatsby Image.
    image = {
        mobileImage =''
        tabletImage =''
        desktopImage =''
        description =''
    } //Required if NOT coming from CMS
>
    <ColumnOne>
        // Content goes here
    </ColumnOne>
    <ColumnTwo>
        // Content goes here
    </ColumnTwo>
</AwardsAndAccolades>

`;

const SgAwardsAndAccolades = () => {
    useEffect(() => {
        // call the highlightAll() function to style our code blocks
        Prism.highlightAll();
    });

    const allAwardsAndAccolades = useStaticQuery(graphql`
        query SGallAwardContainers {
            allKontentItemAwardsAndAccolades {
                nodes {
                    system {
                        id
                    }
                    elements {
                        title {
                            value
                        }
                        image {
                            value {
                                description
                                name
                                fluid(maxWidth: 1200) {
                                    ...KontentAssetFluid_withWebp
                                }
                            }
                        }
                        column_1 {
                            value
                            modular_content {
                                system {
                                    id
                                    type
                                    codename
                                }
                            }
                        }
                        column_2 {
                            value
                            modular_content {
                                system {
                                    id
                                    type
                                    codename
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    const allAwardAccolades = allAwardsAndAccolades.allKontentItemAwardsAndAccolades.nodes;

    return (
        <SgLayout>
            <section className='sg-content'>
                <div className='container'>
                    <h1>Awards and Accolades</h1>
                    <h3>How to use</h3>
                    <CodeBlock
                        code={awardsAndAccoladesEg}
                    />

                    <h3>Example</h3>
                </div>
            </section>
            <section className="project-example">
                <AwardsAndAccolades
                    title="Awards and Accolades"
                    image={AwardsAndAccoladesData.backgroundImage}
                >
                    <ColumnOne>
                        <h3>Doyle's Guide</h3>
                        {AwardsAndAccoladesData.column1Awards.map((item, i) => {
                            return <AwardItem key={i} {...item} />;
                        })}
                    </ColumnOne>
                    <ColumnTwo>
                        <h3>Best Lawyers</h3>
                        {AwardsAndAccoladesData.column2Awards.map((item, i) => {
                            return <AwardItem key={i} {...item} />;
                        })}
                        <h3>Best 2nd lot in 2nd column</h3>
                        {AwardsAndAccoladesData.column2Awards.map((item, i) => {
                            return <AwardItem key={i} {...item} />;
                        })}
                    </ColumnTwo>
                </AwardsAndAccolades>
            </section>
            <section className="sg-content">
                <div className="container">
                    <h2>All LIVE Kontent awards &amp; accolades</h2>
                </div>
            </section>
            <section className="project-example">
                {allAwardAccolades.map((item, i) => {
                    return <AwardsAndAccoladesKontent key={i} {...item}/>;
                })}
            </section>
        </SgLayout>
    );
};

export default SgAwardsAndAccolades;
